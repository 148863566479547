import { getLoginRedirectUrl } from 'admin/utils/login-redirect'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const LoginRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const redirectUrl = getLoginRedirectUrl()
    navigate(redirectUrl ?? '/agencies')
  }, [])

  return null
}

export default LoginRedirect
