import { useQuery } from '@tanstack/react-query'
import httpClient from 'utils/http-client'

const STATS_KEY = ['stats']

type Stats = {
  all_documents: number
  current_documents: number
  not_ok_documents: number
  emails_sent_to_employee: number
  invitation_sent: number
  invitation_accepted: number
  complete_folders: number
  folders_with_3_or_more_documents: number
  fraudulent_folders: number
}

export const useStats = () =>
  useQuery<Stats>({
    queryKey: STATS_KEY,
    queryFn: () => httpClient<Stats>('/stats'),
    retry: false,
  })
