import React from 'react'
import { DEFAULT_SORT } from 'admin/utils/react-admin'
import skribe_constants from 'utils/constants'

import { ListProps, Pagination, List as RAList } from 'react-admin'

const List = ({ children, ...props }: ListProps) => (
  <RAList
    sort={DEFAULT_SORT}
    perPage={skribe_constants.PER_PAGE}
    pagination={<Pagination rowsPerPageOptions={[50]} />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </RAList>
)

export default List
