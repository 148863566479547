import React from 'react'
import { Datagrid, TextField, DateField, ReferenceField } from 'react-admin'
import List from 'admin/components/List'

export const AgencyReferralsList = () => (
  <List>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="agency_name" />
      <TextField source="contact_name" />
      <ReferenceField source="employee_id" reference="employees" link="show" label="Employee" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
)
