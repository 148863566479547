import { fetchUtils, SortPayload, DataProvider, RaRecord, UpdateParams, CreateParams } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'
import { csrfToken, updateAppVersion } from 'utils/http-client'

const BASE_URL = window.location.origin
export const DEFAULT_SORT: SortPayload = { field: 'created_at', order: 'DESC' }

const customJSONHttpClient = async (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers || !(options.headers instanceof Headers)) {
    options.headers = new Headers(options.headers)
  }
  options.headers.set('X-CSRF-TOKEN', csrfToken())
  options.headers.set('Accept', 'application/json')
  // Using fetchJson as our httpClient doesn't return headers, which are needed for pagination
  const result = await fetchUtils.fetchJson(url, options)
  updateAppVersion(result.headers)
  return result
}

const baseDataProvider = simpleRestProvider(BASE_URL, customJSONHttpClient)

// By default react-admin uses JSON for create and update requests, but we need to use FormData for file uploads
// This is a simple way to override the default behavior only for the resources that need it
type DataProviderFormDataConfig = Record<string, (params: any) => FormData> // eslint-disable-line @typescript-eslint/no-explicit-any

export const getDataProvider = (formDataConfig: DataProviderFormDataConfig): DataProvider => ({
  ...baseDataProvider,
  update<T extends RaRecord>(resource: string, params: UpdateParams<T>) {
    if (formDataConfig[resource]) {
      const formData = formDataConfig[resource](params.data)
      const result = customJSONHttpClient(`${BASE_URL}/${resource}/${params.id}`, {
        method: 'PUT',
        body: formData,
      }).then(({ json }: { json: T }) => ({ data: json }))
      return result
    }
    return baseDataProvider.update(resource, params)
  },
  create<T extends RaRecord>(resource: string, params: CreateParams<T>) {
    if (formDataConfig[resource]) {
      const formData = formDataConfig[resource](params.data)
      const result = customJSONHttpClient(`${BASE_URL}/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }: { json: T }) => ({ data: json }))
      return result
    }
    return baseDataProvider.create(resource, params)
  },
})
