import toast from 'react-hot-toast'
import { sendError } from 'utils/appsignal'
import { QueryCache, QueryClient } from '@tanstack/react-query'
import i18next from 'i18next'
import { HttpClientError } from './http-client'

const logAndReportError = (error: Error) => {
  sendError(error)
  toast.error(i18next.t('common.unknown_error'))
  console.log(error.message) // eslint-disable-line no-console
}

export const getDefaultQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (error) => {
          logAndReportError(error)
        },
      },
      queries: {
        retry: 1,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        if (error instanceof HttpClientError) {
          if (!error.response) {
            return
          }
          if (error.response.status >= 500) {
            logAndReportError(error)
          }
        } else {
          logAndReportError(error)
        }
      },
    }),
  })
