import React from 'react'
import { Layout as ReactAdminLayout, LayoutProps, Menu as ReactAdminMenu } from 'react-admin'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import QueryStatsIcon from '@mui/icons-material/QueryStats'

import constants from 'utils/constants'
import useSpaUpdater from 'hooks/useSpaUpdater'
import { useBreadcrumbsUpdater } from 'utils/appsignal'
import ErrorPage from 'admin/components/ErrorPage'
import AppBar from 'admin/components/AppBar'

type ExternalLinkMenuItemProps = {
  href: string
  title: string
}

const ExternalLinkMenuItem = ({ href, title }: ExternalLinkMenuItemProps) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="px-4 py-2 hover:bg-gray-100">
    {title}
    <OpenInNewIcon fontSize="small" className="ml-2" />
  </a>
)

const Menu = () => (
  <ReactAdminMenu>
    <ReactAdminMenu.ResourceItems />
    <ReactAdminMenu.Item to="/stats" primaryText="Stats" leftIcon={<QueryStatsIcon />} />
    {constants.MAILER_SHOW_PREVIEWS ? <ExternalLinkMenuItem href="/rails/mailers" title="Mailers" /> : null}
    <ExternalLinkMenuItem href="/good_job" title="Jobs" />
    <ExternalLinkMenuItem href="/blazer" title="Data" />
    <ExternalLinkMenuItem href="/maintenance_tasks" title="Maintenance tasks" />
  </ReactAdminMenu>
)

const Layout = (props: LayoutProps) => {
  useSpaUpdater()
  useBreadcrumbsUpdater()
  return <ReactAdminLayout {...props} menu={Menu} error={ErrorPage} appBar={AppBar} /> // eslint-disable-line react/jsx-props-no-spreading
}

export default Layout
