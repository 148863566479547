import { useSearchParams } from 'react-router-dom'

export const shouldRedirectToLogin = (): boolean => window.location.pathname !== '/login'

export const loginPathWithRedirect = (): string => {
  if (window.location.pathname === '/') {
    return '/login'
  }
  return `/login?redirect=${window.location.pathname}`
}

export const useURLRedirectPath = () => {
  const [search] = useSearchParams()

  const redirect = search.get('redirect')

  if (!redirect) {
    return null
  }
  try {
    const url = new URL(redirect)

    if (url.origin !== window.location.origin) {
      return null
    }
  } catch (_) {
    return redirect
  }

  return redirect
}
