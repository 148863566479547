import React from 'react'
import classNames from 'classnames'

import iconsPaths from './Icon/iconsPaths'

export type IconName = keyof typeof iconsPaths
export type IconUiStyle = 'primary' | 'accent' | 'light' | 'info' | 'success' | 'error' | 'warning' | 'white'

export type IconProps = {
  name: IconName
  className?: string
  ariaLabel?: string
  size?: 'small' | 'medium' | 'large' | 'xlarge'
  uiStyle?: IconUiStyle
}

const colorByStyle = {
  primary: 'text-iconPrimary',
  accent: 'text-iconAccent',
  light: 'text-iconLight',
  info: 'text-info',
  success: 'text-success',
  error: 'text-error',
  warning: 'text-warningVibrant',
  white: 'text-white',
}

const pxSizeBySize = {
  small: '20',
  medium: '24',
  large: '32',
  xlarge: '48',
}

const Icon = ({ name, className, size = 'small', uiStyle = 'primary', ariaLabel }: IconProps) => (
  <svg
    width={pxSizeBySize[size]}
    height={pxSizeBySize[size]}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(colorByStyle[uiStyle], className)}
    fill="currentColor"
    aria-label={ariaLabel}
  >
    {iconsPaths[name]}
  </svg>
)

export default Icon
