import React, { useEffect, useState } from 'react'
import { useListContext, useRefresh, Button, useRedirect } from 'react-admin'
import { Merge } from '@mui/icons-material'
import httpClient, { HttpClientError } from 'utils/http-client'
import toast from 'react-hot-toast'

type MergeDocumentResponse = {
  id: string
}

const MergeButton = () => {
  const { selectedIds, onUnselectItems } = useListContext()
  const [loading, setLoading] = useState(false)
  const refresh = useRefresh()
  const redirect = useRedirect()

  const handleClick = () => {
    setLoading(true)
    httpClient<MergeDocumentResponse>('/documents/merge', { method: 'post', body: { documents_ids: selectedIds } })
      .then(({ id }: MergeDocumentResponse) => {
        refresh()
        onUnselectItems()
        redirect('show', 'documents', id)
      })
      .catch((error: HttpClientError) => {
        switch (error.message) {
          case 'not_found':
            toast.error("L'un des documents n'existe pas")
            break
          case 'different_group':
            toast.error("Les documents n'appartiennent pas au même groupe")
            break
          case 'different_slugs':
            toast.error("Les documents n'ont pas le même type (slug)")
            break
          default:
            toast.error('Une erreur est survenue lors de la fusion des documents')
            break
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => () => onUnselectItems(), [])

  return (
    <Button label="Fusionner les documents" onClick={handleClick} disabled={selectedIds.length !== 2 || loading}>
      <Merge />
    </Button>
  )
}

export default MergeButton
