import React from 'react'
import classNames from 'classnames'
import Loader from './Loader'

const classNameByStyle = {
  primary:
    'bg-primary text-white enabled:hover:bg-primaryHover enabled:focus:ring enabled:focus:ring-primary enabled:focus:ring-offset-2 enabled:active:bg-primaryActive disabled:bg-primaryDisabled disabled:text-textTertiary',
  secondary:
    'bg-white text-black border border-borderMedium enabled:hover:bg-secondaryHover enabled:focus:ring enabled:focus:ring-primary enabled:focus:ring-offset-2 enabled:active:bg-secondaryActive disabled:bg-primaryDisabled disabled:text-textTertiary',
  delete:
    'bg-error text-white border border-borderMedium enabled:hover:bg-errorLight enabled:focus:ring enabled:focus:ring-error enabled:focus:ring-offset-2 enabled:active:bg-errorVibrant disabled:bg-primaryDisabled disabled:text-textTertiary',
  transparent:
    'bg-transparent text-white enabled:hover:bg-zinc-400/50 enabled:focus:ring enabled:focus:ring-bg-zinc enabled:focus:ring-offset-2 enabled:active:bg-zinc-400/50 disabled:text-zinc-500',
}

type TypeSubmitProps = {
  type?: 'submit'
  onClick?: () => void
}

type TypeButtonProps = {
  type?: 'button'
  onClick: () => void
}

export type ButtonUiStyle = 'primary' | 'secondary' | 'delete' | 'transparent'

type ButtonProps = (TypeSubmitProps | TypeButtonProps) & {
  disabled?: boolean
  name: string
  ariaLabel?: string
  uiStyle?: ButtonUiStyle
  className?: string
  loading?: boolean
}

const Button = ({
  children,
  onClick,
  type = 'button',
  disabled,
  name,
  ariaLabel,
  loading = false,
  uiStyle = 'primary',
  className = '',
}: React.PropsWithChildren<ButtonProps>) => (
  <button
    className={classNames(
      'px-3 py-2 rounded-md enabled:cursor-pointer flex flex-row justify-center items-center gap-1',
      classNameByStyle[uiStyle],
      className
    )}
    type={type === 'submit' ? 'submit' : 'button'}
    disabled={disabled ?? loading}
    onClick={onClick}
    name={name}
    aria-label={ariaLabel}
  >
    {loading ? <Loader /> : children}
  </button>
)

export default Button
