const LOGIN_REDIRECT_URL = 'redirectUrl'

export const storeLoginRedirectUrl = () => {
  localStorage.setItem(LOGIN_REDIRECT_URL, window.location.hash.replace('#', ''))
}

export const getLoginRedirectUrl = () => {
  const redirectUrl = localStorage.getItem(LOGIN_REDIRECT_URL)
  if (!redirectUrl) {
    return
  }
  localStorage.removeItem(LOGIN_REDIRECT_URL)
  return redirectUrl
}
