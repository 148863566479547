import React from 'react'
import { TextInput, TextInputProps, Validator } from 'react-admin'

// eslint-disable-next-line no-useless-escape
const SOCIAL_SECURITY_NUMBER_REGEX = /\A[12]\d{2}(0[1-9]|1[0-2])\d{10}\z/

const validateSocialSecurityNumber = (value: string): string | undefined => {
  if (!value) {
    return undefined
  }
  if (SOCIAL_SECURITY_NUMBER_REGEX.exec(value)) {
    return 'Le numéro de sécurité sociale est invalide'
  }

  const firstPart = parseInt(value.slice(0, 13), 10)
  const controlPart = parseInt(value.slice(13, 15), 10)

  if (97 - (firstPart % 97) !== controlPart) {
    return 'Le numéro de sécurité sociale est invalide'
  }

  return undefined
}

const SocialSecurityNumberInput = ({ source, label, validate = [] }: TextInputProps & { validate?: Validator[] }) => (
  <TextInput source={source} label={label} validate={[validateSocialSecurityNumber, ...validate]} />
)

export default SocialSecurityNumberInput
