import React from 'react'
import classNames from 'classnames'

type TextProps = {
  type?: 'title' | 'subtitle' | 'text' | 'subtext' | 'smalltext'
  uiStyle?: 'bold'
  center?: boolean
  className?: string
}

const Text = ({ type = 'text', center = false, uiStyle, className, children }: React.PropsWithChildren<TextProps>) => {
  if (type === 'title') {
    return <h1 className={classNames('text-2xl font-semibold', className)}>{children}</h1>
  }
  if (type === 'subtitle') {
    return <h2 className={classNames('text-xl font-semibold', className)}>{children}</h2>
  }
  return (
    <p
      className={classNames(className, {
        'font-medium': uiStyle === 'bold',
        'text-textSecondary': type === 'subtext',
        'text-xs': type === 'smalltext',
        'text-center': center,
      })}
    >
      {children}
    </p>
  )
}

export default Text
