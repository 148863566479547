import React from 'react'
import dayjs from 'dayjs'
import { DateInput, DateInputProps, Validator } from 'react-admin'

const MIN_DATE = dayjs().subtract(15, 'year')
const MAX_DATE = dayjs().subtract(100, 'year')

const dateIsValid = (value: string): undefined | string => {
  if (!value) {
    return undefined
  }
  const date = dayjs(value)

  if (date.isBetween(MIN_DATE, MAX_DATE)) {
    return undefined
  }

  return 'La date de naissance doit être entre 15 et 100 ans'
}

const BirthdateInput = ({ validate = [], ...props }: DateInputProps & { validate?: Validator[] }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DateInput validate={[...validate, dateIsValid]} {...props} />
)

export default BirthdateInput
