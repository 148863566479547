import React from 'react'
import { TextInput, TextInputProps, Validator } from 'react-admin'

const validateBic = (bic: string): string | undefined => {
  if (!bic) {
    return undefined
  }
  if (bic.length !== 8 && bic.length !== 11) {
    return 'Ce code BIC est invalide'
  }

  return undefined
}

const BicInput = ({ source, label, validate = [] }: TextInputProps & { validate?: Validator[] }) => (
  <TextInput source={source} label={label} validate={[validateBic, ...validate]} />
)

export default BicInput
