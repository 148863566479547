import { useState, useEffect } from 'react'
import { sendError } from 'utils/appsignal'
import { getDocument, PDFDocumentProxy } from 'utils/pdf'

type PDFInfo = {
  numPages: number
  pdf: PDFDocumentProxy | null
}

const usePdfViewer = (pdfUrl: string, canvasId: string) => {
  const [pdfInfo, setPdfInfo] = useState<PDFInfo>({ numPages: 0, pdf: null })
  const [currentPage, setCurrentPage] = useState(1)

  const renderPage = async (pdf: PDFDocumentProxy, pageNumber: number) => {
    const page = await pdf.getPage(pageNumber)
    const scale = 1
    const canvas = window.document.getElementById(canvasId) as HTMLCanvasElement
    if (!canvas) {
      sendError(new Error('Canvas not found'))
      return
    }

    const { width } = canvas.getBoundingClientRect()

    const viewport = page.getViewport({ scale: width / page.getViewport({ scale }).width })

    const context = canvas.getContext('2d')
    if (!context) {
      sendError(new Error('Canvas context not found'))
      return
    }
    canvas.height = viewport.height
    canvas.width = viewport.width
    page.render({ canvasContext: context, viewport })
  }

  const openPdf = async () => {
    const pdf = await getDocument(pdfUrl).promise

    setPdfInfo({ numPages: pdf.numPages, pdf })
    renderPage(pdf, 1)
  }

  useEffect(() => {
    if (pdfInfo.pdf) {
      renderPage(pdfInfo.pdf, currentPage)
    }
  }, [currentPage])

  const nextPage = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1)
  }

  const previousPage = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1)
  }

  return { openPdf, numPages: pdfInfo.numPages, nextPage, previousPage, currentPage }
}

export default usePdfViewer
