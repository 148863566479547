import React from 'react'
import { TextInput, TextInputProps, Validator } from 'react-admin'

const CODE_LENGTHS = {
  BE: 16,
  FR: 27,
}

const mod97 = (string: string) => {
  let checksum = parseInt(string.slice(0, 2), 10)

  for (let offset = 2; offset < string.length; offset += 7) {
    const fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

const validateIban = (iban: string): string | undefined => {
  if (!iban) {
    return undefined
  }
  const parsedIban = iban.toUpperCase().replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
  // check syntax and length
  if (!code || parsedIban.length !== CODE_LENGTHS[code[1] as keyof typeof CODE_LENGTHS]) {
    return 'Cet IBAN est invalide'
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => String(letter.charCodeAt(0) - 55))
  // final check
  if (mod97(digits) === 1) {
    return undefined
  }

  return 'Cet IBAN est invalide'
}

const IbanInput = ({ source, label, validate = [] }: TextInputProps & { validate?: Validator[] }) => (
  <TextInput source={source} label={label} validate={[validateIban, ...validate]} />
)

export default IbanInput
