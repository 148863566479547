import React, { useState } from 'react'
import { useRefresh, Button } from 'react-admin'
import Transform from '@mui/icons-material/Transform'
import httpClient from 'utils/http-client'
import toast from 'react-hot-toast'

type ConvertButtonProps = {
  documentId: string
}

const ConvertButton = ({ documentId }: ConvertButtonProps) => {
  const [loading, setLoading] = useState(false)
  const refresh = useRefresh()

  const handleClick = () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('Êtes-vous sûr de vouloir convertir ce document ?')) return

    setLoading(true)
    httpClient(`/documents/${documentId}/convert`, { method: 'post' })
      .then(() => {
        setLoading(false)
        refresh()
      })
      .catch(() => toast.error('Une erreur est survenue lors de la convertion du document'))
      .finally(() => setLoading(false))
  }

  return (
    <Button label="Convertir le document en PDF" onClick={handleClick} disabled={loading} color="secondary">
      <Transform />
    </Button>
  )
}

export default ConvertButton
