import Text from 'components/Text'
import React from 'react'
import { AppBar as ReactAdminAppBar, TitlePortal } from 'react-admin'
import { isProductionDeployment, isStagingDeployment } from 'utils/environment'

const AppBar = () => {
  const productionDeployment = isProductionDeployment()

  if (!productionDeployment && !isStagingDeployment()) {
    return <ReactAdminAppBar color="primary" position="sticky" />
  }

  return (
    <ReactAdminAppBar color={productionDeployment ? 'error' : 'success'} position="sticky">
      <TitlePortal />
      <Text type="title" className="mr-2">
        {productionDeployment ? 'Production' : 'Staging'}
      </Text>
    </ReactAdminAppBar>
  )
}

export default AppBar
