import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'

const EditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
)

export default EditToolbar
