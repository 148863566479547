import ClickableDiv from 'components/ClickableDiv'
import i18next from 'i18next'
import React from 'react'
import toast, { ToastBar, Toaster as ReactHotToaster } from 'react-hot-toast'

const Toaster = () => (
  <ReactHotToaster position="bottom-center" containerClassName="toast-container" containerStyle={{ bottom: '40px' }}>
    {(t) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <ClickableDiv label={i18next.t('common.close')} onClick={() => toast.dismiss(t.id)} className="flex flex-row">
            {icon}
            {message}
          </ClickableDiv>
        )}
      </ToastBar>
    )}
  </ReactHotToaster>
)

export default Toaster
