import React from 'react'
import { Datagrid, TextField, DateField, TextInput, useRecordContext, useCreatePath, Link } from 'react-admin'
import List from 'admin/components/List'

type TargetType = 'Employee' | 'Agent' | 'Agency'

const REFERENCE_BY_TARGET_TYPE: Record<TargetType, string> = {
  Employee: 'employees',
  Agent: 'agents',
  Agency: 'agencies',
}

const TargetField = ({ ...props }) => {
  const record = useRecordContext<{ target_type: TargetType; target_id: string }>(props)
  const createPath = useCreatePath()

  if (!record) {
    return null
  }

  const reference = REFERENCE_BY_TARGET_TYPE[record.target_type]

  return (
    <Link
      to={createPath({ resource: reference, type: 'show', id: record.target_id })}
      target="_blank"
    >{`${record.target_type} (${record.target_id})`}</Link>
  )
}

export const CanalTrackersList = () => (
  <List filters={[<TextInput key="1" alwaysOn label="Template" source="template" />]}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="template" />
      <DateField source="created_at" />
      <TargetField source="target_id" />
    </Datagrid>
  </List>
)
